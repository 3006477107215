<template>
  <div class="mx-4 mt-4 overflow-hidden rounded border border-gray-700 bg-gray-800 shadow-lg">
    <div :class="`border-l-2 border-${accent}-400`" class="flex justify-between p-4">
      <!-- this is to avoid purging of classes DO NOT REMOVE -->
      <!-- <div class="border-yellow-400 border-red-400 border-green-400 border-blue-400"/> -->
      <div class="flex w-full flex-col justify-center">
        <span :class="`text-${accent}-400`" class="text-xs font-semibold uppercase tracking-tight line-clamp-1">
          {{ title }}
        </span>
        <p class="mt-1 text-sm font-medium leading-4 text-gray-100 line-clamp-3" v-html="notification.text.message"></p>
        <div class="mt-2" v-if="notification.onClick">
          <BaseButton size="xs" @press="handleClick()">Read More</BaseButton>
        </div>
      </div>
      <div class="ml-4 flex items-center">
        <button @click="close(notification.id)" class="text-gray-400 hover:text-gray-100 focus:outline-none">
          <svg
            class="h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    notification: {
      type: Object
    },
    close: {
      type: Function
    }
  },
  computed: {
    title: function () {
      let type = this.notification.text.type;
      if (this.notification.text.title) {
        return this.notification.text.title;
      } else if (type && type != 'alert' && type != 'notice') {
        return type;
      } else {
        return 'Message';
      }
    },
    accent: function () {
      let color = 'blue';
      let type = this.notification.text.type;
      if (type == 'error') {
        color = 'red';
      } else if (type == 'warning' || type == 'alert') {
        color = 'yellow';
      } else if (type == 'success' || type == 'notice') {
        color = 'green';
      }
      return color;
    }
  },
  methods: {
    handleClick() {
      this.notification.onClick();
    }
  }
};
</script>
